<script setup>

import avatar from '@/assets/images/statics/avatar.png';

import consultants from '~/configs/consultants.js'
const leasing = useLeasingParams();
const {onWindowEvent} = useWindowEvents();
const calculator = useCalculator();
const route = useRoute();
const url = useRequestURL();

const favs = useFavorites();
const isDragging = useState('slideControlDragging', () => false);

const props = defineProps({
  car: {
    type: Object,
    default: () => ({})
  },
  offer: {
    type: Object,
    default: () => ({})
  },
  thin: Boolean,
  imageOnScroll: {
    type: Number,
    default: null
  },
  getOffer: Boolean,
  noButton: Boolean,
  noTop: Boolean,
})

const emits = defineEmits(['adjust']);

const currentCar = computed(() => props.car)
const isDiscounted = computed(() => {
  return currentCar.value?.catalogPrice !== currentCar.value?.price;
})

const ow = computed(() => currentCar.value ? currentCar.value.price * (leasing.refParams.value.initialFee / 100) : 0);
const rv = computed(() => currentCar.value ? currentCar.value.price * (leasing.refParams.value.repurchase / 100) : 0);

const showImage = ref(false);

const addOrRemoveFromFavorites = (add, car) => {
  !add ? favs.remove(car) : favs.add(car);
}

if (props.imageOnScroll) {
  onWindowEvent('scroll', () => {
    showImage.value = window.scrollY >= props.imageOnScroll
  })
}

const isVehisConsultant = ref('');

onMounted(() => {
  console.log(props.car);

  consultants.forEach(consultant => {
    if(props.offer?.email === consultant.email ) isVehisConsultant.value = consultant.img
  });
})
</script>

<template>
  <div class="rounded-lg shadow-box print:shadow-none print:border print:border-gray-300 select-none bg-white pointer-events-auto"
       :class="{'min-w-[300px]':!thin, 'min-w-[280px]':thin, 'mt-16':imageOnScroll && !showImage}"
       @dragstart.prevent=""
  >
    <Accordion :expanded="imageOnScroll && showImage" class="print:hidden">
      <div class="px-4 pt-2 font-extralight transition-all">
        <CarImageBox :car="car" class='mb-2 -mx-4' />
        <div class="text-lg font-normal mt-2 text-ellipsis overflow-hidden border-b-gray-400 border-b pb-2.5">
          {{ car?.versionName }}
        </div>
      </div>
    </Accordion>
    <div v-if="!(imageOnScroll && showImage) && !(offer?.email?.includes('digital-order')) && isVehisConsultant" class="relative flex justify-center mt-4">
      <div class="z-40 rounded-full w-[114px] h-[114px] transition-all overflow-hidden"
           :class="[{'-mt-20':imageOnScroll && !showImage || noTop}]">
        <img :src="isVehisConsultant" />
      </div>
    </div>
    <div class="px-4 pb-6 font-extralight">
      <div class="space-y-2.5">
        <div>
          <div ref="refPrices" class="grid grid-cols-2 gap-2.5 py-2.5">
            <CarCardInfo label="Ofertę przygotował" :content="offer?.owner" :sub-content="offer?.email" :sub-content2="offer?.phone" />
            <div class="flex items-center">
              <div class="print:hidden text-azure text-xs">
                <EffectCopy :string-to-copy="url.href"><IconCopy class="inline-block !size-4 align-bottom"/> Skopiuj link</EffectCopy>
              </div>
            </div>
            <CarCardInfo label="Ważna do" :content="offer?.expires" />
            <CarCardInfo label="ID oferty" :content="offer?.id" />
            <CarCardPrice :is-liczak="true" :offer-client="offer?.client" :label="isDiscounted ? 'Cena katalogowa' : 'Cena dla Ciebie'" :price="currentCar?.catalogPrice" :discounted="isDiscounted" />
            <CarCardPrice :is-liczak="true" :offer-client="offer?.client" v-if="isDiscounted" label="Cena dla Ciebie" :price="currentCar?.price" />
          </div>
          <div class="print:hidden" v-if="!noButton">
            <template v-if="!getOffer">
              <ButtonRounded :href="{ name: 'offer', params: { id: currentCar?.sellcar_id } }">Przejdź do oferty</ButtonRounded>
            </template>
            <template v-else>
              <ButtonRounded :target="'_blank'" color="azure" :href="`/srv/download-pdf?uuid=${route.params.uuid}`">Pobierz PDF</ButtonRounded>
            </template>
          </div>
        </div>
        <div>
          <div ref="refIcons" class="grid grid-cols-2 gap-2.5 pt-1 overflow-hidden">
            <div class="text-xs lowercase flex items-center">
              <div class="shrink-0">
                <IconCarFuel />
              </div>
              <div>{{ currentCar?.fuelType }}</div>
            </div>
            <div class="text-xs lowercase flex items-center">
              <IconCarGearBox />
              <div>{{ currentCar?.gearboxType }}</div>
            </div>
            <div class="text-xs lowercase flex items-center">
              <div class="shrink-0">
                <IconCarPaint />
              </div>
              <div class="max-h-7 overflow-hidden text-ellipsis">
                <div>{{ currentCar?.color }}</div>
                <div class="normal-case">{{ currentCar?.paint }}</div>
              </div>
            </div>
            <div class="text-xs lowercase flex items-center">
              <div class="shrink-0">
                <IconCarPower />
              </div>
              <div>{{ currentCar?.horsePower }}
                <span class="uppercase">KM</span>
              </div>
            </div>
            <div class="text-xs lowercase flex items-center">
              <div class="shrink-0">
                <IconCarDrive />
              </div>
              <div>{{ currentCar?.driveType }}</div>
            </div>
            <div class="text-xs lowercase flex items-center">
              <div class="shrink-0">
                <IconCarSeats />
              </div>
              <div>{{ currentCar?.seats }}</div>
            </div>
          </div>

        </div>
      </div>
    </div>
    <div v-if="currentCar?.group_id" class="text-xs font-normal text-center w-full py-1 bg-[#F3F4F6]">ID samochodu: <EffectCopy>{{ currentCar?.group_id }}</EffectCopy></div>
  </div>
</template>
